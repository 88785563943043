
/*================== Base Classes =============================*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #222;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*=================== Template Classes =============================*/
.footerClass {
  background-color: #000;
  min-height: 250px;
  border-top: 1px solid #333;
  margin-top: 70px;
  overflow-y: hidden;
}

.appBarClass {
  background-color: #000!important;
  border-bottom: 1px solid #333;
}

.homePageClass {
  min-height: 500px;
}

.destyleLink, .destyleLink:active, .destyleLink:focus, .destyleLink:visited, .destyleLink:hover   {
  text-decoration: inherit;
  color: inherit;
  background-color: inherit;
}

.drawerStyle {
  padding-top: 0px!important;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
  min-width: 250px;
}

.drawerSubheader {
  background-color: #111!important;
  color: #fff!important;
  border-bottom: 1px solid #333;
  text-align: right;
}

.drawerItem {
  border-bottom: 1px solid #333;
}

.drawerItemAdmin {
  border-bottom: 1px solid #333;
  background-color: #1118;
}

.navbarItem {
  margin-left: 5px!important;
  margin-right: 5px!important;
  border: 1px solid #333!important;
  background-color: #1115!important;
  min-width: 150px!important;
  text-align: center;
}

.navbarItemAdmin {
  background-color: #4445!important;
}


.accountBar {
  font-size: 0.85rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 10px;
  background-color: #333;
  padding: 10px;
  border-bottom: 1px solid #fff3;
}

.loginRequired {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* User Management */
.userBackground {
  background-color: #000;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
}

.userHeader {
  text-align: right;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
}

/*=================== End Template Classes =============================*/


/* Timesheet Class */

.gridClass, .gridClassAdmin {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 15px;
  transition: 0.5s;
  border: 1px solid #222;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}

.gridClass:hover  {
  background-color: #111;
  color: #fff;
  border-radius: 10px;
  padding: 15px;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid #444;
  box-shadow: 0px 0 2px 1px #4da7d8;
}

.gridClassAdmin:hover  {
  background-color: #222;
  color: #fff;
  border-radius: 10px;
  padding: 15px;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid #444;
  box-shadow: 0px 0 2px 1px #ecff00;
}

/* QR Generator Class */
.qrText {
  background-color: #fff !important;
  border-radius: 5px;
}


/* ================ Minivan Anim */

.minivanAnim {
  animation: minivan 2s infinite alternate;
}

@keyframes minivan {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

@keyframes minivanExhaust {
  0% {
    transform: scale(1.0);
    opacity: 0.2;
  }

  85% {
    transform: scale(0.5) translate(-10px);
  }

  100% {
    transform: scale(0.5) translate(-10px);
    opacity: 0;
  }
}

.minivanExhaust {
  position: absolute;
  bottom: 10px;
  left: -10px;
  background-color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  animation: minivanExhaust 2s infinite;
}


/* No Select */
.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}


.externalLink:hover {
  border-color: #00ff70;
}